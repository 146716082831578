import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['references.full%%%title', 'id']
const dataTemplate = {
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  leftIcons: [
    {
      uniqueKey: () => uuidv4(),
      icon: 'arrow_right',
      iconSize: 'large',
      infos: {},
    },
  ],
  rightIcons: [],
}

const api = ``
const apiSpecificItem = ``

const relay = [
  {
    type: 'Issue',
    api: process.env.REACT_APP_RELAY_ADDRESS,
    data: {
      body: {
        method: 'GET',
        header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
        url: '##GITLAB_URL##/projects/%project_id%/issues/%project_issue_id%',
      },
    },
    urlKeywordsToBeReplaced: ['%project_id%', '%project_issue_id%'],
    objectsWithKeywords: [],
  },
  {
    type: 'Epic',
    api: process.env.REACT_APP_RELAY_ADDRESS,
    data: {
      body: {
        method: 'GET',
        header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
        url: '##GITLAB_URL##/projects/%epics_project_id%/epics/%epic%',
      },
    },
    urlKeywordsToBeReplaced: ['%epics_project_id%', '%epics%'],
    objectsWithKeywords: [],
  },
]

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { marginRight: '30px', marginLeft: '30px' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
  },
}

const titleDataList = {
  title: 'Project %type%',
  variant: 'h4',
}

export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
  relay,
}
