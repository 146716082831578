import React, { useState, useEffect, useRef } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Button, IconButton, Avatar, Snackbar } from '@mui/material'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'

import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Link } from 'react-router-dom'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import EnhancedIconButton from './EnhancedIconButton'

import { Paper } from '@mui/material'
import { styled } from '@mui/system'

import { v4 as uuidv4 } from 'uuid'

import UserService from '../services/UserService'

import Badge from '@mui/material/Badge'
import MailIcon from '@mui/icons-material/Mail'
import CloseIcon from '@mui/icons-material/Close'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SignalrMessageList from './SignalrMessageList'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { useUserContext } from '../contexts/userContext'
import { useMessageContext } from '../contexts/messageContext'
import { height } from '@mui/system'
import { useSnackbar } from '../contexts/snackbarContext'
import MuiAlert from '@mui/material/Alert'
import { Task } from '@mui/icons-material'

import { isNonEmptyString } from '../utils'

function Logout() {
  UserService.doLogout()
}

const StyledAppBar = styled(AppBar)((theme) => ({
  root: {
    '&.MuiAppBar-root': {
      '& .MuiToolbar-root': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
  },
}))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function TitleBar(props) {
  let messageContext = useMessageContext()

  const [isLeftOpen, setIsLeftOpen] = React.useState(false)
  const [isRightOpen, setIsRightOpen] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbar, setSnackbar] = useState(null)

  const snackbarContext = useSnackbar()

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackbar(false)
    // snackbarContext.setSnackbarInfo(null)
  }

  const user = useUserContext().getUser()

  const toggleLeftDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsLeftOpen(!isLeftOpen)
  }

  const toggleRightDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsRightOpen(!isRightOpen)
  }

  // const pages = props.pages
  //getting pages in which the user has permission to access depending on the role
  const pages = props.pages.filter((page) => {
    if (page.name === 'previousNSSI' || page.name === 'Observables') return null
    return page.visibleForRoles.find((vfr) => {
      if (!user.roles || user.roles.length === 0) return page
      return (
        user.roles.map((role) => role.split('|')[1]).includes(vfr) &&
        user.roles
          .map((role) => role.split('|')[0])
          .includes(
            page.path
              .replace(process.env.REACT_APP_CLIENT_PATH_NAME, '')
              .replace('/:id?', '')
              .replace('/', '')
              .toLowerCase()
          )
      )
    })
  })

  const [valueTab, setValueTab] = React.useState(0)
  useEffect(() => {
    if (props.selectedTab) {
      const index = pages.findIndex((item) => item.path === props.selectedTab)
      setValueTab(index)
    }
  }, [props.selectedTab])

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue)
  }

  //managing message dialog
  const [openMessagesDialog, setOpenMessagesDialog] = React.useState(false)

  const handleAlarmClick = () => {
    setAlarmCounter(0)
    window.location.href = `${process.env.PUBLIC_URL}/alarms`
  }

  const handleClickOpenMessagesDialog = () => {
    setOpenMessagesDialog(true)
  }

  const handleCloseMessagesDialog = () => {
    setOpenMessagesDialog(false)
    console.log(latestChat.current)
    setChat(latestChat.current)
    setChatCounter(() => {
      const chatCounter = latestChat.current.length
      console.log(chatCounter)
      return chatCounter
    })

    messageContext.setTaskId()
  }

  const handleClearMessagesDialog = () => {
    setChatCounter()
    setChat([])
  }

  const [chat, setChat] = useState([])
  const latestChat = useRef(null)
  const [chatCounter, setChatCounter] = useState()

  const [alarmCounter, setAlarmCounter] = useState(null)

  latestChat.current = chat

  const [taskId, setTaskId] = useState()

  useEffect(() => {
    messageContext.taskId && setTaskId(messageContext.taskId)
    messageContext.taskId && setOpenMessagesDialog(true)
  }, [messageContext, messageContext.taskId])

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_CHATHUB_ADDRESS)
      .withAutomaticReconnect()
      .build()

    connection
      .start()
      .then((result) => {
        console.log('SignalR connected!')

        connection.on('ReceiveMessage', (user, message) => {
          if (message.startsWith(`(${process.env.REACT_APP_CHATHUB_GROUP}) `)) {
            message = message.replace(
              `(${process.env.REACT_APP_CHATHUB_GROUP}) `,
              ''
            )
            console.log(['signalR', user, message])

            if (user === 'ALARM') {
              console.log(user)
              setAlarmCounter((prev) => prev + 1)
            } else {
              const updatedChat = [...latestChat.current]
              updatedChat.unshift({ user: user, message: JSON.parse(message) })
              setChat(updatedChat)
              setChatCounter(updatedChat.length)
            }
          }
        })

        connection.invoke(
          'JoinGroup',
          `reactuser-${uuidv4()}`,
          process.env.REACT_APP_CHATHUB_GROUP
        )
      })
      .catch((e) => console.log('Connection failed: ', e))
  }, [])

  useEffect(() => {
    if (snackbarContext?.snackbarInfo) {
      console.log(snackbarContext?.snackbarInfo)
      setSnackbar(snackbarContext.snackbarInfo)
      setOpenSnackbar(true)
    }
  }, [snackbarContext])

  return (
    <>
      {/* {snackbar && ( */}
      <Snackbar
        open={snackbar?.open === false ? false : openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar?.status || ''}
          sx={{ width: '100%' }}
        >
          {snackbar?.message || ''}
        </Alert>
      </Snackbar>
      {/* )} */}

      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar position='static'>
          <Toolbar>
            <Paper
              elevation={5}
              sx={{
                marginLeft: '0px',
                marginRight: '2em',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                width: '80px',
                border: '1px solid black',
                borderTopRightRadius: '35px',
                borderBottomRightRadius: '35px',
                backgroundColor: 'white',
                transition: 'background .3s, transform 2s ',
                color: 'black',
                ':hover': {
                  background: '#FFF700',
                },
              }}
            >
              <EnhancedIconButton
                icon='settings_input_antenna'
                size='large'
                tooltip='push to open menu'
                color='inherit'
                aria-label='menu'
                onClick={toggleLeftDrawer()}
              />
            </Paper>

            <Drawer
              anchor='left'
              open={isLeftOpen}
              onClose={toggleLeftDrawer()}
            >
              <Typography
                variant='h6'
                component='div'
                sx={{
                  marginTop: '1vw',
                  width: '80%',
                  marginLeft: '10%',
                  textAlign: 'center',
                }}
              >
                {process.env.REACT_APP_CLIENT_NAME}
              </Typography>
              <List sx={{ marginTop: '1em' }}>
                {pages.map((page) => (
                  <Box
                    key={uuidv4()}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      borderTopWidth: '3px',
                      borderTopStyle: 'solid',
                      borderImage:
                        'linear-gradient(to right, transparent, gold, transparent) 1',
                      borderImageSlice: '1',
                      transition: 'background 0.5s ease',
                      ':hover': {
                        background: '#242f57',
                      },
                    }}
                  >
                    <EnhancedIconButton icon='arrow_right' />
                    <ListItem
                      key={page.path}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}
                      component={Link}
                      to={page.path}
                      sx={{ width: '70%' }}
                      onClick={() => {
                        window.localStorage.getItem('selectedItemFromHome') &&
                          window.localStorage.removeItem('selectedItemFromHome')
                      }}
                    >
                      {page.name}
                    </ListItem>
                  </Box>
                ))}
              </List>
            </Drawer>

            <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
              {process.env.REACT_APP_CLIENT_NAME}
            </Typography>

            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {pages.map((page) => {
                return (
                  <Tab
                    key={uuidv4()}
                    sx={{
                      backgroundColor:
                        valueTab === pages.indexOf(page)
                          ? 'white'
                          : 'primary.main',
                      color: 'white',
                    }}
                    label={page.name}
                    component={Link}
                    to={page.path}
                    onClick={() => {
                      window.localStorage.getItem('selectedItemFromHome') &&
                        window.localStorage.removeItem('selectedItemFromHome')
                    }}
                  />
                )
              })}
            </Tabs>

            {/* <Badge badgeContent={alarmCounter} color='secondary'>
              <NotificationImportantIcon
                color='action'
                fontSize='large'
                onClick={handleAlarmClick}
                sx={{ cursor: alarmCounter > 0 ? 'pointer' : 'unset' }}
              />
            </Badge> */}
            <Badge badgeContent={chatCounter} color='secondary'>
              <MailIcon
                color='action'
                fontSize='large'
                onClick={handleClickOpenMessagesDialog}
                sx={{ cursor: chatCounter > 0 ? 'pointer' : 'unset' }}
              />
            </Badge>
            <Paper
              elevation={5}
              sx={{
                marginLeft: '2em',
                marginRight: '0px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '80px',
                border: '1px solid black',
                borderTopLeftRadius: '35px',
                borderBottomLeftRadius: '35px',
                backgroundColor: 'white',
                transition: 'background .3s, transform 2s ',
                color: 'black',
                ':hover': {
                  background: '#FFF700',
                },
              }}
            >
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='span'
                onClick={toggleRightDrawer()}
              >
                <Avatar sx={{ background: 'white', color: 'primary.main' }}>
                  {isNonEmptyString(user?.firstName) &&
                  isNonEmptyString(user?.lastName)
                    ? `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`
                    : `${user.name[0].toUpperCase()}`}
                </Avatar>
              </IconButton>
            </Paper>
            <Drawer
              anchor='right'
              open={isRightOpen}
              onClose={toggleRightDrawer()}
            >
              <Typography
                variant='h6'
                component='div'
                sx={{
                  marginTop: '1vw',
                  width: '80%',
                  marginLeft: '10%',
                  textAlign: 'center',
                }}
              >
                {isNonEmptyString(user?.firstName) &&
                isNonEmptyString(user?.lastName)
                  ? `${user.name}: ${user.firstName} ${user.lastName}`
                  : `${user.name}`}
              </Typography>
              <List sx={{ marginTop: '1em', minWidth: '8vw' }}>
                <ListItem>
                  <Box
                    key={uuidv4()}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      borderTopWidth: '3px',
                      borderTopStyle: 'solid',
                      borderImage:
                        'linear-gradient(to right, transparent, gold, transparent) 1',
                      borderImageSlice: '1',
                      transition: 'background 0.5s ease',
                      ':hover': {
                        background: '#242f57',
                      },
                    }}
                  >
                    <EnhancedIconButton
                      icon='logout'
                      size='large'
                      tooltip='Logout'
                      color='inherit'
                      aria-label='menu'
                      onClick={Logout}
                      sx={{ marginTop: '10%' }}
                    />
                  </Box>
                </ListItem>
              </List>
            </Drawer>
          </Toolbar>
        </StyledAppBar>
      </Box>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openMessagesDialog}
        onClose={handleCloseMessagesDialog}
      >
        <DialogTitle>Tasks</DialogTitle>
        <DialogContent style={{ height: '50vh' }}>
          <SignalrMessageList messages={chat} taskId={taskId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearMessagesDialog}>
            Clear Notifications
          </Button>
          <Button onClick={handleCloseMessagesDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
