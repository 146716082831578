import { v4 as uuidv4 } from 'uuid'
import cardMain from './observables-main'
const doNothing = () => {}

export const relay = [
  {
    type: 'Developer',
    api: process.env.REACT_APP_RELAY_ADDRESS,
    data: {
      body: {
        method: 'GET',
        header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
        url: '##GITLAB_URL##/projects/%helm_project_id%/members/all?state=active',
      },
    },
    urlKeywordsToBeReplaced: ['%helm_project_id%'],
    objectsWithKeywords: [],
  },
  {
    type: 'Issues',
    api: process.env.REACT_APP_RELAY_ADDRESS,
    data: {
      body: {
        method: 'POST',
        header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
        url: '##GITLAB_URL##/projects/%project%/issues',
        body: {},
      },
    },
    urlKeywordsToBeReplaced: ['%project%'],
    objectsWithKeywords: [],
  },
]

export const outsideActions = [
  {
    uniqueKey: uuidv4(),
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    type: 'dialog',
    dialog: {
      title: 'Add New %%%',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        title: {
          title: 'Title',
          type: 'text',
          md: 4,
          mandatory: true,
          showAsterisk: true,
        },
        description: {
          title: 'Description',
          type: 'text',
          md: 8,
        },
        project: {
          title: 'Project',
          type: 'combo',
          md: 6,
          url: `http://127.0.0.1:8010/api/gitlab_products_manager/gitlab_products_manager/project?product_id=%product_id%`,
          valueMember: 'project_id',
          displayMember: 'name',
          mandatory: true,
          showAsterisk: true,
        },
        developer: {
          isRelay: true,
          title: 'Developer',
          type: 'combo',
          md: 6,
          valueMember: 'id',
          displayMember: 'name',
          mandatory: true,
          showAsterisk: true,
        },
      },
      tabs: [],
      onDataChange: doNothing,
      buttons: [{ type: 'cancel' }, { type: 'save' }],
    },
  },
]
