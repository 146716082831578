import React, { useState, useEffect } from 'react'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../customHooks/useSaveDeleteCardBoxContent'
import useHttpService from '../../customHooks/useHttpService'
// import { useSnackbar } from '../../contexts/snackbarContext'

import CardBox from '../../components/CardBox/CardBox'
import { isNonEmptyArray, isNonEmptyObject } from '../../utils'

import { list as observableList } from './schema/observables-list'
import cardMain from './schema/observables-main'

import {
  outsideActions,
  relay as outsideRelay,
} from './schema/observables-outside'
import { useLocation } from 'react-router-dom'

const Observables = (props) => {
  const [schemaList, setschemaList] = useState(null)
  const [productIssues, setproductIssues] = useState(null)
  const [productEpics, setproductEpics] = useState(null)
  const [developers, setdevelopers] = useState(null)
  const [bottomActions, setbottomActions] = useState(null)
  const [alreadyUpdatedBottomActions, setalreadyUpdatedBottomActions] =
    useState(false)
  const [location, setlocation] = useState(useLocation())

  const issuesApi = `${process.env.REACT_APP_API_ADDRESS}/gitlab_products_manager/gitlab_products_manager/issues`
  const epicsApi = `${process.env.REACT_APP_API_ADDRESS}/gitlab_products_manager/gitlab_products_manager/epics`

  const cardBoxContext = useCardBoxContext()
  const httpService = useHttpService()

  //Fetching all the products (issues or epics in each function)
  const fetchProductIssues = async () => {
    return fetch(
      `${issuesApi}?product_id=${location.state.product_id}&product_issue_id=${location.state.product_issue_id}`
    ).then((data) => data)
  }

  const fetchProductEpics = async () => {
    return fetch(
      `${epicsApi}?product_id=${location.state.product_id}&product_epic_id=${location.state.product_epic_id}`
    ).then((data) => data)
  }

  //based on the location.state.type, fetch issues or epics
  useEffect(() => {
    const getProductIssues = async () => {
      const fetchedIssues = await fetchProductIssues()
      const fetchedJsonIssues = await fetchedIssues.json()
      fetchedJsonIssues.result && setproductIssues(fetchedJsonIssues.data)
    }
    const getProductEpics = async () => {
      const fetchedEpics = await fetchProductEpics()
      const fetchedJsonEpics = await fetchedEpics.json()
      fetchedJsonEpics.result && setproductEpics(fetchedJsonEpics.data)
    }
    if (location.state.type === 'Issue') getProductIssues()
    else getProductEpics()
  }, [])

  //add the fetched products to the listSchema, inside the relay.objectsWithKeywords property array
  useEffect(() => {
    console.log(productIssues, productEpics)
    if (isNonEmptyArray(productIssues) || isNonEmptyArray(productEpics)) {
      let listElements = [
        ...(isNonEmptyArray(productIssues) ? [...productIssues] : []),
        ...(isNonEmptyArray(productEpics) ? [...productEpics] : []),
      ]

      let tmpSchemaList = { ...observableList }
      tmpSchemaList.relay.forEach((relay, i) => {
        if (relay.type === location.state.type) {
          if (!isNonEmptyArray(relay.objectsWithKeywords))
            relay.objectsWithKeywords = [
              ...relay.objectsWithKeywords,
              ...listElements,
            ]
        } else delete tmpSchemaList.relay[i]
      })

      console.log(listElements)
      console.log(tmpSchemaList)
      setschemaList(tmpSchemaList)
    }
  }, [productIssues, productEpics])

  //calling the init function of the cardBox context
  useEffect(() => {
    if (isNonEmptyObject(schemaList)) {
      cardBoxContext.init(schemaList, cardMain)
    }
  }, [schemaList])

  const fetchOutsideRelayData = () => {
    outsideRelay.forEach((relay) => {
      if (relay.type === 'Developer') {
        relay.objectsWithKeywords = [location.state]
      }
      // if (relay.type === 'Project') {
      //   const listElements = [
      //     ...(isNonEmptyArray(productIssues) ? [...productIssues] : []),
      //     ...(isNonEmptyArray(productEpics) ? [...productEpics] : []),
      //   ]
      //   relay.objectsWithKeywords = listElements
      // }
      httpService.getRelayData(outsideRelay, (data) => {
        if (isNonEmptyArray(data)) setdevelopers(data)
      })
    })
    console.log(outsideRelay)
  }

  //Manage the outside actions, mapping them to the bottomAction state
  useEffect(() => {
    if (isNonEmptyArray(outsideActions)) {
      outsideActions.forEach((action) => {
        if (action.type === 'dialog') {
          action.dialog.dialogData = {}
          action.dialog.dialogData.title = location.state.title
          action.dialog.dialogData.description = location.state.description
          action.dialog.title = action.dialog.title.replace(
            '%%%',
            location.state.type
          )
          Object.keys(action.dialog.schemaFields).forEach((key) => {
            //fetching the developers through the relay api
            if (
              action.dialog.schemaFields[key].hasOwnProperty('isRelay') &&
              action.dialog.schemaFields[key].isRelay
            ) {
              fetchOutsideRelayData()
            }
            //adding the product id to the project api for the combo box
            if (action.dialog.schemaFields[key].title === 'Project') {
              action.dialog.schemaFields[key].url = action.dialog.schemaFields[
                key
              ].url.replace('%product_id%', location.state.product_id)
            }
          })
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
            if (button.type === 'cancel') {
              button.onClick = () => {}
            }
            if (button.type === 'save') {
              button.onClick = (e, data, context) => {
                console.log(e, data, context)
                let saveRelay = outsideRelay.find(
                  (relay) => relay.type === 'Issues'
                )
                if (!data.hasOwnProperty('project_id'))
                  data.project_id = location.state.project_id
                saveRelay.objectsWithKeywords = Array.isArray(data)
                  ? data
                  : [data]
                saveRelay.data.body.body = data

                httpService.postRelayAPI(saveRelay, (res) => {
                  const bodyData = {
                    product_id: location.state.product_id,
                    product_issue_id: location.state.product_issue_id,
                    project_issue_id: res[0].iid,
                    project_id: res[0].project_id,
                  }
                  // postCardBoxMainJsonData(data, action, context)
                  httpService.postAPI(issuesApi, bodyData, (result) => {
                    cardBoxContext.emptyListData()
                    cardBoxContext.emptyMainCard()
                    cardBoxContext.emptyChoosenItem()
                  })
                })
              }
            }
          })
        }
      })
      setbottomActions(outsideActions)
    }
  }, [outsideActions])

  //adding data to the dialog.project element of bottomAction
  // useEffect(() => {
  //   if (
  //     isNonEmptyArray(projectsByProduct) &&
  //     isNonEmptyArray(bottomActions) &&
  //     !alreadyUpdatedBottomActions
  //   ) {
  //     let tmpBottomActions = [...bottomActions]
  //     tmpBottomActions.forEach((tba) => {
  //       if (tba.type === 'dialog') {
  //         Object.keys(tba.dialog.schemaFields).forEach((key) => {
  //           if (tba.dialog.schemaFields[key].title === 'Project') {
  //             tba.dialog.schemaFields[key].fetchedData = projectsByProduct
  //           }
  //         })
  //       }
  //     })
  //     setalreadyUpdatedBottomActions(true)
  //     setbottomActions(tmpBottomActions)
  //   }
  // }, [projectsByProduct, bottomActions])

  useEffect(() => {
    if (
      isNonEmptyArray(developers) &&
      isNonEmptyArray(bottomActions) &&
      !alreadyUpdatedBottomActions
    ) {
      let tmpBottomActions = [...bottomActions]
      tmpBottomActions.forEach((tba) => {
        if (tba.type === 'dialog') {
          Object.keys(tba.dialog.schemaFields).forEach((key) => {
            if (tba.dialog.schemaFields[key].title === 'Developer') {
              tba.dialog.schemaFields[key].fetchedData = developers
            }
          })
        }
      })
      console.log(tmpBottomActions)
      setalreadyUpdatedBottomActions(true)
      setbottomActions(tmpBottomActions)
    }
  }, [developers, bottomActions])

  // const {
  //   putCardBoxMainJsonData,
  //   deleteCardBoxMainJsonData,
  //   deleteCardBoxListData,
  //   postCardBoxMainJsonData,
  //   importExcel,
  //   exportExcel,
  // } = useSaveDeleteCardBoxContent()

  // //init the cardBoxContext with the list coming from the schema
  // useEffect(() => {
  //   cardBoxContext.init(list, cardMain)
  // }, [])

  //Rendering the cardBox with data (if any) or with an empty list
  return (
    <>
      {isNonEmptyArray(bottomActions) && isNonEmptyObject(schemaList) ? (
        <CardBox
          // listData={list}
          cardBoxStyle={schemaList.cardBoxStyleList}
          title={schemaList.titleDataList.title.replace(
            '%type%',
            `${location.state.type}s`
          )}
          titleVariant={schemaList.titleDataList.variant}
          apiDataToShow={schemaList.apiDataToShow}
          dataTemplate={schemaList.dataTemplate}
          outsideActions={bottomActions}
        />
      ) : isNonEmptyArray(bottomActions) ? (
        <CardBox
          // listData={list}
          cardBoxStyle={observableList.cardBoxStyleList}
          title={observableList.titleDataList.title.replace(
            '%type%',
            `${location.state.type}s`
          )}
          titleVariant={observableList.titleDataList.variant}
          apiDataToShow={observableList.apiDataToShow}
          dataTemplate={observableList.dataTemplate}
          outsideActions={bottomActions}
        />
      ) : (
        <CardBox
          // listData={list}
          cardBoxStyle={observableList.cardBoxStyleList}
          title={observableList.titleDataList.title.replace(
            '%type%',
            `${location.state.type}s`
          )}
          titleVariant={observableList.titleDataList.variant}
          apiDataToShow={observableList.apiDataToShow}
          dataTemplate={observableList.dataTemplate}
        />
      )}
    </>
  )
  // return <div>Ciao</div>
}

export default Observables
