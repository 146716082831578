import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useConfirm } from 'material-ui-confirm'
import useHttpService from '../customHooks/useHttpService'
import {
  fromDBDateToGUIDate,
  isNonEmptyString,
  isNonEmptyObject,
  isNonEmptyArray,
} from '../utils'
// import { Prompt } from 'react-router-dom'

const CardBoxContext = createContext()
const UpdateCardBoxContext = createContext()

const useCardBoxContext = () => {
  return useContext(CardBoxContext)
}

const useUpdateCardBoxContext = () => {
  return useContext(UpdateCardBoxContext)
}

const CardBoxContextProvider = ({ children }) => {
  const [choosenItem, setChoosenItem] = useState(null)
  const [choosenIndexItem, setChoosenIndexItem] = useState(null)
  const [listData, setListData] = useState(null)
  const [cardListSchema, setCardListSchema] = useState(null)
  const [cardMainSchema, setCardMainSchema] = useState(null)
  const [mainCardTitle, setMainCardTitle] = useState('')
  const [selectedItemFromOtherPage, setSelectedItemFromOtherPage] =
    useState(null)

  const [idFromUrl, setIdFromUrl] = useState(null)
  const [isRefreshed, setIsRefreshed] = useState(false)
  // const [modifiedData, setModifiedData] = useState()
  const modifiedData = useRef(false)

  const confirm = useConfirm()

  // const warningOnLeaveWithoutSaving = useRef({
  //   warning: () =>
  //     confirm({
  //       title: 'Confirm exit',
  //       description: `You didn't save some data! Are you sure you want to leave?`,
  //       confirmationText: 'LEAVE',
  //       cancellationText: 'CANCEL',
  //     }),
  //   callback: () => {},
  // })

  const httpService = useHttpService()
  const pathParams = useParams()

  const emptyListData = () => setListData(null)
  const emptyMainCard = () => {
    setSelectedItemFromOtherPage(null)
    setChoosenIndexItem(null)
    setChoosenItem(null)
    setMainCardTitle('')
  }
  const emptyChoosenItem = () => {
    setChoosenIndexItem(null)
    setChoosenItem(null)
  }

  const resetSchemas = () => {
    setCardListSchema(null)
    setCardMainSchema(null)
  }

  const updateList = () => {
    getListData()
  }

  const init = (listSchema, cardSchema) => {
    console.log(listSchema, cardSchema)
    setCardListSchema({
      data: listSchema.listData || null,
      style: listSchema.cardBoxStyleList || null,
      title: listSchema.titleDataList || '',
      api: listSchema.api || null,
      apiSpecificItem: listSchema.apiSpecificItem || null,
      apiDataToShow: listSchema.apiDataToShow || null,
      dataTemplate: listSchema.dataTemplate,
      relay: listSchema.relay || null,
    })
    setCardMainSchema(cardSchema)
  }

  const processData = (jsonData) => {
    let tmpData = []
    jsonData.forEach((item, index) => {
      tmpData.push({
        ...cardListSchema.dataTemplate,
        ...(cardListSchema.dataTemplate.rightIcons && {
          //if rightIcons array was defined in the schema file...
          rightIcons: [
            ...(item.showClock //we insert 'schedule' icon in the array only if the item is scheduled
              ? [
                  {
                    uniqueKey: uuidv4(),
                    icon: 'schedule',
                    content: '',
                    size: 'small',
                    tooltip: 'This element is scheduled!',
                    type: 'scheduled',
                  },
                ]
              : []),
            ...cardListSchema.dataTemplate.rightIcons,
          ],
        }),
        //we add red color to the left icons if the workflowState is DRAFT
        leftIcons: cardListSchema?.dataTemplate?.leftIcons?.map((el) => {
          return {
            ...el,
            ...(item.workflowState === 'DRAFT' && { color: 'red' }),
            ...(item.workflowState === 'DRAFT' && {
              tooltip: 'This element is in DRAFT state',
            }),
          }
        }),
        //we check if the content is a date in order to display it correctly
        content:
          getValidApiToShow(item)?.charAt(4) === '-' &&
          getValidApiToShow(item)?.charAt(7) === '-' &&
          getValidApiToShow(item)?.charAt(10) === 'T' &&
          getValidApiToShow(item)?.charAt(13) === ':' &&
          getValidApiToShow(item)?.charAt(16) === ':'
            ? fromDBDateToGUIDate(getValidApiToShow(item))
            : getValidApiToShow(item),
        infos: item,
      })
    })
    return tmpData
  }

  const getListData = async () => {
    console.log(cardListSchema)
    if (cardListSchema.relay) {
      const data = await httpService.getRelayData(
        cardListSchema.relay,
        processData
      )
      setListData(data)
    } else {
      httpService.getAPI(cardListSchema.api, (jsonData) => {
        const data = processData(jsonData)

        // console.log(tmpData)
        setListData(data)
      })
    }
  }

  const getValidApiToShow = (item) => {
    let title = []
    console.log(cardListSchema.apiDataToShow[0])
    if (cardListSchema.apiDataToShow[0].includes('%%%')) {
      let splittedTitle = cardListSchema.apiDataToShow[0].split('%%%')

      console.log(splittedTitle)
      splittedTitle.forEach((st) => {
        if (st.includes('.')) {
          console.log('in')
          const properties = st.split('.')
          let value = item

          for (let prop of properties) {
            console.log(prop)
            if (value && value.hasOwnProperty(prop)) {
              value = value[prop]
            } else {
              value = undefined
              break
            }
            console.log(value)
            if (typeof value !== 'object') title.push(value)
          }
        } else {
          title.push(item[st])
        }
      })
    }
    console.log(title)

    if (isNonEmptyArray(title)) {
      title = title.map((t) => {
        if (t.includes('/') && t.includes('#')) {
          return t.split('/')[t.split('/').length - 1].split('#')[0]
        } else return t
      })

      return title.join(' - ')
    } else {
      return (
        item[cardListSchema.apiDataToShow[0]] ||
        item[cardListSchema.apiDataToShow[1]]
      )
    }
  }

  const handleSetSelectedListItem = (e, item, index) => {
    let url = null
    if (window.location.pathname.includes('previousNSSI')) {
      url = `${cardListSchema.apiSpecificItem}?id=${item.infos.id}&progid=${item.infos.progid}`
    } else if (window.location.pathname.includes('Observables')) {
      openSelectedCard(e, item, index)
      return
    } else {
      url = `${cardListSchema.apiSpecificItem}${item.infos.id}`
    }
    console.log(item)
    httpService.getAPI(url, (data) => {
      item.infos = data[0]
      openSelectedCard(e, item, index)
    })
    // if (modifiedData.current === true) {
    //   confirm({
    //     title: 'Confirm exit',
    //     description: `You didn't save some data! Are you sure you want to leave?`,
    //     confirmationText: 'LEAVE',
    //     cancellationText: 'CANCEL',
    //   }).then(() => {
    //     console.log('then')
    //     modifiedData.current = false
    //     openSelectedCard(e, item, index)
    //   })
    // } else {
    //   openSelectedCard(e, item, index)
    // }
  }

  const openSelectedCard = (e, item, index) => {
    emptyMainCard()

    console.log(item)
    if (item?.content) {
      setMainCardTitle(item.content)
    } else if (typeof e === 'string') {
      setMainCardTitle(e)
    } else {
      setMainCardTitle(
        e.target.outerText.split('\\')[1] ||
          e.target.outerText ||
          e.target.textContent ||
          null
      )
    }

    // we split the pathname like the following /pagename/:name?/:id?
    // then we add to the pathname the (name and) id of the selected item
    let uuidPattern =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    let pathElemArray = window.location.pathname.split('/')
    let pathElemId = pathElemArray[pathElemArray.length - 1]

    if (item?.infos?.id) {
      pathElemId = item.infos.id
    } else {
      pathElemId = ''
    }

    // if (pathElemId === ':id' || !pathElemId.match(uuidPattern)) {
    //   pathElemId = ''
    // } else {
    //   pathElemId = item?.infos?.id != null ? item.infos.id : ''
    // }

    // pathElemId =
    //   (pathElemId === ':id' || !pathElemId.match(uuidPattern)) &&
    //   item?.infos?.id != null
    //     ? item.infos.id
    //     : ''

    pathElemArray[pathElemArray.length - 1] = pathElemId
    pathElemArray = pathElemArray.join('/')

    window.history.replaceState(null, null, pathElemArray)

    // const pathnameElements = window.location.pathname
    //   .replace(process.env.REACT_APP_CLIENT_PATH_NAME, '')
    //   .split('/')
    // if (pathnameElements.length <= 3) {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     process.env.REACT_APP_CLIENT_PATH_NAME !== ''
    //       ? `${process.env.REACT_APP_CLIENT_PATH_NAME}/${pathnameElements[1]}/${item.infos.id}`
    //       : `/${pathnameElements[1]}/${item.infos.id}`
    //   )
    // } else {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     process.env.REACT_APP_CLIENT_PATH_NAME !== ''
    //       ? `${process.env.REACT_APP_CLIENT_PATH_NAME}/${pathnameElements[1]}/${item.infos.name}/${item.infos.id}`
    //       : `${pathnameElements[1]}/${item.infos.name}/${item.infos.id}`
    //   )
    // }

    //once we select an item, we modify the url to get the NFs releated to the selected nssi

    let tmp = { ...cardMainSchema }
    if (tmp.schema.fields.NF?.baseUrl)
      tmp.schema.fields.NF.url = `${
        tmp.schema.fields.NF.baseUrl.split('?nssid')[0]
      }?nssid=${item.infos.id}`

    //same thing for the network slice
    if (tmp.schema.fields.NetworkSlices?.baseUrl)
      tmp.schema.fields.NetworkSlices.url = `${
        tmp.schema.fields.NetworkSlices.baseUrl.split('?id')[0]
      }?id=${item.infos.id}`

    setChoosenItem(item.infos)
    setChoosenIndexItem(index)
    setCardMainSchema(tmp)
  }

  useEffect(() => {
    if (isRefreshed || idFromUrl == null) {
      const uuidPattern =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

      const pathElemArray = window.location.pathname.split('/')
      const pathElemId = pathElemArray[pathElemArray.length - 1]

      if (pathElemId !== ':id' && pathElemId.match(uuidPattern))
        setIdFromUrl(pathElemId)
      setIsRefreshed(false)
    }
  }, [isRefreshed, idFromUrl])

  useEffect(() => {
    if (idFromUrl != null && listData != null) {
      let item = null
      let index = null
      listData.forEach((el, i) => {
        if (el.infos.id === idFromUrl) {
          item = el
          index = i
        }
      })
      if (item) {
        item.index = index
        setSelectedItemFromOtherPage(item)
      }
      // item && setChoosenItem(item.infos)
      // index && setChoosenIndexItem(index)
    }
  }, [listData, idFromUrl])

  const checkIfNoDataInList = () => {
    return (
      isNonEmptyObject(cardListSchema) &&
      listData == null &&
      cardListSchema.data == null &&
      isNonEmptyObject(cardListSchema.dataTemplate)
    )
  }

  const checkIfApiIsSet = () => {
    return (
      isNonEmptyString(cardListSchema.api) &&
      isNonEmptyArray(cardListSchema.apiDataToShow)
    )
  }

  const checkIfRelayIsSet = () => {
    return isNonEmptyArray(cardListSchema.relay)
  }

  useEffect(() => {
    if (checkIfNoDataInList()) {
      if (checkIfApiIsSet()) {
        getListData()
      } else if (checkIfRelayIsSet()) {
        getListData()
      }
    }

    // console.log(cardListSchema) //true
    // console.log(listData) //false /////////////
    // console.log(cardListSchema?.data) //true
    // console.log(cardListSchema?.api) //false
    // console.log(cardListSchema?.apiDataToShow) //true
    // console.log(cardListSchema?.relay) //true
    // console.log(cardListSchema?.dataTemplate) //false /////////
    // if (
    //   isNonEmptyObject(cardListSchema) &&
    //   listData == null &&
    //   cardListSchema.data == null &&
    //   ((isNonEmptyString(cardListSchema.api) &&
    //     isNonEmptyArray(cardListSchema.apiDataToShow)) ||
    //     isNonEmptyArray(cardListSchema.relay)) &&
    //   isNonEmptyObject(cardListSchema.dataTemplate)
    // ) {
    //   getListData()
    // }
  }, [cardListSchema, listData])

  useEffect(() => {
    let tmp = { ...cardMainSchema }
    let modified = false

    if (
      tmp?.schema?.fields?.NF?.hasOwnProperty('baseUrl') &&
      tmp?.schema?.fields?.NF?.hasOwnProperty('url')
    ) {
      delete tmp.schema.fields.NetworkSlices.url
      modified = true
    }
    if (
      tmp?.schema?.fields?.NF?.hasOwnProperty('baseUrl') &&
      tmp?.schema?.fields?.NF?.hasOwnProperty('url')
    ) {
      delete tmp.schema.fields.NF.url
      modified = true
    }
    modified && setCardMainSchema(tmp)
  }, [cardMainSchema])

  // useEffect(() => {
  //   if (listData) {
  //     setCardListSchema({
  //       ...cardListSchema,
  //       data: listData,
  //     })
  //   }
  // }, [listData])

  // useEffect(() => {
  //   if (listData && choosenItem == null) {
  //     if (pathParams?.id) {
  //       const searchedItem = listData
  //         .map((el, index) => {
  //           if (el.infos.id === pathParams.id) {
  //             return { ...el, index }
  //           }
  //           return null
  //         })
  //         .find((el) => el)
  //       setSelectedItemFromOtherPage(searchedItem)
  //     }
  //   }
  // }, [listData])

  useEffect(() => {
    if (selectedItemFromOtherPage && listData) {
      handleSetSelectedListItem(
        null,
        selectedItemFromOtherPage,
        selectedItemFromOtherPage.index
      )
    }
  }, [listData, selectedItemFromOtherPage])

  useEffect(() => {
    cardMainSchema?.actions?.forEach((el) => {
      const isCorrectIcon =
        el.icon === 'celebration' ||
        el.icon === 'power_settings_new' ||
        el.icon === 'error'
      if (isCorrectIcon && choosenItem) {
        if (choosenItem.administrativeState === 'LOCKED') {
          el.icon = 'power_settings_new'
          el.label = 'OFFLINE'
        } else if (choosenItem.operationalState === 'ENABLED') {
          el.icon = 'celebration'
          el.label = 'ONLINE'
        } else {
          el.icon = 'error'
          el.label = 'FAULT'
        }
      }
    })
  }, [choosenItem])

  // useEffect(() => {
  //   const pathArray = window.location.pathname.split('/')
  //   const id = pathArray[pathArray.length - 1]

  //   let uuidPattern =
  //     /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  //   if (id === ':id' || !id.match(uuidPattern)) {
  //     return
  //   }

  //   let name = null
  //   if (pathArray.includes('previousNSSI')) {
  //     name = pathArray[pathArray - 2]
  //   }

  //   console.log(id, name)

  //   if (listData != null && id != null) {
  //     let item = null
  //     let index = null
  //     listData.forEach((el, i) => {
  //       if (el.infos.id === id) {
  //         item = el
  //         index = i
  //       }
  //     })
  //     item.index = index
  //     console.log(item, index)
  //     setSelectedItemFromOtherPage(item)
  //     // item && setChoosenItem(item.infos)
  //     // index && setChoosenIndexItem(index)
  //   }
  // }, [choosenItem])

  // const alertUser = (e) => {
  //   e.preventDefault()

  //   if (modifiedData.current) {
  //     e.returnValue = 'There are not saved data! Are sure you want to leave?'
  //     return 'There are not saved data! Are sure you want to leave?'
  //   }
  //   return null
  // }

  // // const beforeUnload = () => cardBoxContext.setModifiedData(false)

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener('unload', alertUser)
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     window.removeEventListener('unload', alertUser)
  //   }
  // }, [])

  return (
    <>
      {/* {modifiedData.current && (
        <Prompt
          when={modifiedData.current}
          message='There are not saved data! Are sure you want to leave?'
        />
      )} */}

      <CardBoxContext.Provider
        value={{
          setCardMainSchema,
          choosenItem,
          setChoosenItem,
          choosenIndexItem,
          setChoosenIndexItem,
          listData,
          setListData,
          emptyListData,
          emptyMainCard,
          emptyChoosenItem,
          setMainCardTitle,
          mainCardTitle,
          handleSetSelectedListItem,
          init,
          cardListSchema,
          cardMainSchema,
          modifiedData,
          // warningOnLeaveWithoutSaving,
          selectedItemFromOtherPage,
          setSelectedItemFromOtherPage,
          resetSchemas,
          setIsRefreshed,
          updateList,
        }}
      >
        <UpdateCardBoxContext.Provider
          value={{
            setCardMainSchema,
            setChoosenItem,
            setChoosenIndexItem,
            setListData,
            emptyListData,
            emptyMainCard,
            emptyChoosenItem,
            setMainCardTitle,
            handleSetSelectedListItem,
            init,
            setSelectedItemFromOtherPage,
            resetSchemas,
            setIsRefreshed,
            updateList,
          }}
        >
          {children}
        </UpdateCardBoxContext.Provider>
      </CardBoxContext.Provider>
    </>
  )
}

export { CardBoxContextProvider, useCardBoxContext, useUpdateCardBoxContext }
