import React, { useState, useEffect } from 'react'
import { FormControl, FormLabel, FormHelperText, Tooltip } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import useHttpService from '../customHooks/useHttpService.js'

export default function ListBox(props) {
  const [myURL, setURL] = useState()
  const [selectedValue, setSelected] = useState()
  const [myData, setData] = useState([])
  const [checkedValues, setCheckedValues] = useState([])

  const httpService = useHttpService()

  function queryAPI() {
    httpService.getAPI(myURL, onDataLoaded)
  }

  function onDataLoaded(data) {
    setData(data)
  }

  function SelectChange(e, value) {
    setSelected(value)
    if (props.onSelectChange)
      props.onSelectChange(
        myData.find((row) => row[props.valueMember] === value)
      )
  }

  function CheckChange(e, value) {
    if (checkedValues.includes(value)) {
      for (var i = 0; i < checkedValues.length; i++) {
        if (checkedValues[i] === value) {
          checkedValues.splice(i, 1)
        }
      }
    } else {
      checkedValues.push(value)
    }
    console.log(checkedValues)
    setCheckedValues([...checkedValues])
    if (props.onCheckChange) props.onCheckChange(e, checkedValues)
  }

  useEffect(() => {
    if (props.apiURL) setURL(props.apiURL)
    if (props.data) {
      if (props.data.length > 0) setData(props.data)
    }

    if (props.selectedValue) setSelected(props.selectedValue)
    if (props.checkedValues != null && props.checkedValues.length > 0)
      setCheckedValues(props.checkedValues)
  }, [props])

  useEffect(() => {
    myURL && queryAPI()
  }, [myURL])

  return (
    <Tooltip title={props.tooltip || ''} placement='top'>
      <FormControl
        fullWidth
        required={props.required && props.showAsterisk} //WARNING: putting this, causes the rerendering when a checkbox is selected
        error={props.error || false} //WARNING: putting this, causes the rerendering when a checkbox is selected
      >
        {props.label && (
          <FormLabel
            sx={{
              color:
                props.required && checkedValues.length === 0
                  ? 'text.error'
                  : 'primary.main',
            }}
            component='legend'
          >
            {props.label}
          </FormLabel>
        )}
        <List
          component='nav'
          sx={{
            ...props.sx,
            maxHeight: '200px',
            overflowY: 'auto',
          }}
          dense={props.dense || false}
        >
          {myData &&
            myData.map((option) => (
              <ListItem
                // sx={{ width: '50%' }}
                key={option[props.valueMember]}
                secondaryAction={
                  props.multiselect && (
                    <Checkbox
                      edge='end'
                      onChange={(event) =>
                        CheckChange(event, option[props.valueMember])
                      }
                      checked={
                        checkedValues != null &&
                        checkedValues.includes(option[props.valueMember])
                      }
                      sx={{ color: 'primary.main' }}
                      disabled={props.disabled}
                    />
                  )
                }
              >
                {!props.disabled ? (
                  <ListItemButton
                    selected={selectedValue === option[props.valueMember]}
                    onClick={(event) =>
                      SelectChange(event, option[props.valueMember])
                    }
                  >
                    <ListItemText primary={option[props.displayMember]} />
                  </ListItemButton>
                ) : (
                  <ListItemText primary={option[props.displayMember]} />
                )}
              </ListItem>
            ))}
        </List>
        <FormHelperText sx={{ color: 'text.error' }}>
          {props.required &&
            checkedValues.length === 0 &&
            'Field is mandatory!'}
        </FormHelperText>
      </FormControl>
    </Tooltip>
  )
}
