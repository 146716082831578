import { v4 as uuidv4 } from 'uuid'

export const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    tooltip: 'Delete',
    uniqueKey: uuidv4(),
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    onClick: saveData,
    // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    tooltip: 'Save Changes',
    uniqueKey: uuidv4(),
  },
]

const schemaFields = {
  created_at: {
    title: 'Created at',
    md: 6,
    type: 'date',
    disabled: true,
  },
  description: {
    title: 'Description',
    type: 'text',
    md: 8,
  },
  id: {
    title: 'ID',
    type: 'text',
    md: 8,
    disabled: true,
  },
  // prova: {
  //   title: 'JSON',
  //   type: 'json',
  //   md: 8,
  // },
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
