import Dashboard from './webpages/gitlab/Dashboard'
import Observables from './webpages/gitlab/Observables'

const routes = [
  {
    path:
      process.env.REACT_APP_ENV === 'prod'
        ? `${process.env.REACT_APP_CLIENT_PATH_NAME}/`
        : '/',
    name: 'Home',
    visibleForRoles: ['admin', 'user', 'readonly'],
    Component: Dashboard,
  },
  {
    path:
      process.env.REACT_APP_ENV === 'prod'
        ? `${process.env.REACT_APP_CLIENT_PATH_NAME}/dashboard`
        : '/dashboard',
    name: 'Dashboard',
    visibleForRoles: ['admin', 'user', 'readonly'],
    Component: Dashboard,
  },
  {
    path:
      process.env.REACT_APP_ENV === 'prod'
        ? `${process.env.REACT_APP_CLIENT_PATH_NAME}/observables/:id`
        : '/observables/:id',
    name: 'Observables',
    visibleForRoles: ['admin', 'user', 'readonly'],
    Component: Observables,
  },
]

export default routes
