import React from 'react'
import { Box } from '@mui/material'
import CardBoxList from './CardBoxList'
import CardBoxMainJson from './CardBoxMainJson'
import CardBoxBottomIcons from './CardBoxBottomIcons'
import { useCardBoxContext } from '../../contexts/cardBoxContext'

/**
 * @author Caloger Lo Bue
 * @desc Component used to create a card with a list of items (icon and text) and a card with the content of the selected item from the list
 * @param {Object} cardList the object describing the list
 * @example
 * const cardList = {
    data: listData,
    style: cardBoxStyleList,
    title: titleDataList,
  } 
  @desc where 
  - listData is an array of object representing the entries inside the List like the following
  @example
  [
    {
      icon: 'arrow_right', //the icon from https://fonts.googleapis.com/icon?family=Material+Icons
      content: 'item1', //the content of an item
      iconSize: 'large', //the size of the icon
      onTextClick: handleOnClick, //a callback function 
      onIconClick: doNothing, //a callback function
    },
    {
      icon: 'arrow_left',
      content: 'item2',
      onTextClick: handleOnClick,
    },
    {
      content: 'item3',
    },
  ]
  @desc
  - cardBoxStyleList, if defined, overrides the style property of the card box. It consists of four objects: 
      - cardStyle, 
      - listContainerStyle, 
      - textItemStyle, 
      - titleStyle
  the following is an example: 
  @example
  {
    cardStyle: {
      width: '20vw',
      height: '75vh',
      backgroundColor: '#131c3d',
    },
    textItemStyle: { textAlign: 'right', color: '#80732b' },
    titleStyle: {
      marginTop: '30px',
      backgroundColor: '#242f57',
      paddingLeft: '7%',
      paddingRight: '7%',
      color: '#80732b',
      width: '105%',
    },
  }
  @desc
  - title, an object describing the title
  @example
  {
    title: 'List of NSI',
    variant: 'h4',
  }
  @param {Object} cardMain an object describing the selected item from the list
  @example
  const cardMain = {
    actions: cardMainActions,
    schema: {
      fields: schemaFields,
      values: schemaValues,
    },
    onDataChange,
    style: cardBoxStyleMain,
    title: titleDataMain,
  }
  @desc
  where:
  - actions is an array of icon buttons like the following
  @example
  [
    {
      icon: 'celebration', //the icon from https://fonts.googleapis.com/icon?family=Material+Icons
      iconSize: 'large', //the size of the icon (one of 'small' and 'large'). If not set, it will be medium by default
      place: 'top', //the position inside the ard (one of 'top' and 'bottom')
      label: 'ACTIVE', //the label placed next to the icon
      onClick: doNothing, //a callback function
    },
    {
      icon: 'delete',
      place: 'bottom',
      onClick: doNothing,
      tooltip: 'Delete',
    },
    {
      icon: 'save',
      iconSize: 'large',
      place: 'bottom',
      onClick: doNothing,
      tooltip: 'Save Modifies',
    },
  ]
  @desc
  - schema is an object used to build a form on fly. It consists of two fields: 
      - fields
      - values
  @example
  const fields = {
    NSIDName: {
      title: 'NSID name', //the label visible on the TextField
      type: 'string', //the type of the TextField (one of 'string', 'number', 'combo' for a dropdown list, 'html')
      md: 12, //the size of the TextField in a range from 1 to 12
      disabled: true, //makes the field disabled 
    },
    NSTID: {
      title: 'NSTID',
      type: 'string',
      md: 12,
    },
    PLMNIDa: {
      title: 'PLMN ID a',
      type: 'string',
      md: 12,
    },
    NSSa: {
      title: 'NSS',
      type: 'string',
      md: 6,
    },
    NSSb: {
      title: 'NSS',
      type: 'string',
      md: 6,
    },
  }
  
  const values = {
    NSIDName: 'MIoT Milano1', //the key MUST be the same of one key belonging to the 'fields' object in order to map the value with the proper field
    NSTID: 'NSTIDx',
    PLMNIDa: 'PLMNIDa',
    NSSa: 'NSSIDa',
    NSSb: 'NSSIDb',
  } 
  //TODO: deal with 'combo' type

  @desc
  - onDataChange, a callback function
  - style, if defined, overrides the style property of the card box. It consists of three objects:
      - cardStyle
      - titleStyle
      - iconStyle

  @example
  const cardBoxStyleMain = {
    cardStyle: {
      width: '65vw',
      height: '75vh',
      backgroundColor: '#1f123e',
    },
    titleStyle: {
      marginTop: '30px',
      backgroundColor: '#332457',
      paddingLeft: '6%',
      paddingRight: '6%',
      color: '#80662b',
      width: '102%',
    },
    iconStyle: {
      topBox: {
        border: '1px solid red',
      },
      bottomBox: {
        border: '1px solid green',
      },
    },
  }

  @desc
  - title, an object describing only the variant. The title itself is taken from the selected item of the list
  @example
  const titleDataMain = {
    variant: 'h4',
  }

  @param {object} outsideActions an array of actions to be placed under the cards, adding additional functionalities
  @example
  const outsideActions = [
    {
      icon: 'add_circle',
      label: 'Add New NSI',
      place: 'bottom', //only bottom value is permitted. Maybe it is useless...
      onClick: doNothing, //a callback function
    },
    {
      icon: 'add_circle',
      label: 'Add New NST (csv admitted)',
      place: 'bottom',
      onClick: doNothing,
      upload: true, //if the button should permit to upload a file
    },
  ]

* @returns the CardBox component
*/

function CardBox(props) {
  const outsideActions = props.outsideActions

  console.log(useCardBoxContext())
  const {
    cardListSchema,
    cardMainSchema,
    handleSetSelectedListItem,
    listData,
    choosenItem,
  } = useCardBoxContext()

  console.log(props.list)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          width: '100vw',
          // border: '1px solid white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '90vw',
            // border: '1px solid red',
          }}
        >
          <CardBoxList
            cardBoxStyle={props.cardBoxStyle || cardListSchema.style}
            listData={props.listData || listData}
            title={props.title || cardListSchema.title.title}
            titleVariant={props.titleVariant || cardListSchema.title.variant}
            onSelectedItem={handleSetSelectedListItem}
            selectedItemFromOtherPage={cardListSchema?.searchedElement || null}
            api={cardListSchema?.api || null}
            apiDataToShow={props.apiDataToShow || cardListSchema.apiDataToShow}
            dataTemplate={props.dataTemplate || cardListSchema.dataTemplate}
          />
          {choosenItem && (
            <CardBoxMainJson
              cardBoxStyle={cardMainSchema.style}
              titleVariant={cardMainSchema.title.variant}
              actionButtons={cardMainSchema.actions}
              schemaFields={cardMainSchema.schema.fields}
              onDataChange={(data) => {
                console.log(data)
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '2%',
            // border: '1px solid green',
            width: '90vw',
          }}
        >
          <CardBoxBottomIcons actionButtons={outsideActions} />
        </Box>
      </Box>
    </>
  )
}

export default CardBox
