import React from 'react'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import customtheme from './theme.js'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import TitleBar from './components/TitleBar'
import { ConfirmProvider } from 'material-ui-confirm'
import RouterBreadcrumbs from './components/RouterBreadcrumbs'
import routes from './routes'
import { UserContextProvider } from './contexts/userContext.js'
import { MessageContextProvider } from './contexts/messageContext.js'
import { CardBoxContextProvider } from './contexts/cardBoxContext.js'
import { SnackbarProvider } from './contexts/snackbarContext.js'

export default function App() {
  //if (localStorage.getItem('token') === null) {
  // if (!UserService.isLoggedIn()) {
  //   UserService.doLogin()
  //   //return <Login />
  //   return <div></div>
  // } else {
  //   console.log(UserService.getToken())
  //   console.log(UserService.getRoles('localhostWebpage'))
  // }

  return (
    <ThemeProvider theme={customtheme}>
      <UserContextProvider>
        <MessageContextProvider>
          <CssBaseline />
          <ConfirmProvider>
            <Router>
              {routes.map(({ path, name, Component }, key) => {
                const crumbs = routes.filter(({ path }) =>
                  window.location.pathname.includes(path)
                )
                return (
                  <Routes>
                    <Route
                      exact
                      path={path}
                      key={key}
                      element={
                        <SnackbarProvider>
                          <TitleBar
                            pages={routes}
                            selectedTab={path}
                          ></TitleBar>
                          <RouterBreadcrumbs crumbs={crumbs} />
                          <CardBoxContextProvider>
                            <Component />
                          </CardBoxContextProvider>
                        </SnackbarProvider>
                      }
                    />
                  </Routes>
                )
              })}
              {/* <div>Fixed footer</div> */}
            </Router>
          </ConfirmProvider>
        </MessageContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  )
}
